<template>
  <div class="cont-whole-box">
    <div style="border-bottom: 1px solid #f2f2f2; padding-top: 30px;">
      <div class="top-butt-box">
        <div
          class="cent-butt cent-butt-sty whole-col whole-col-hov"
          @click="handleChangeTabs(1)"
        >
          按指标层级
        </div>
        <div class="cent-butt whole-col-hov" @click="handleChangeTabs(2)">
          按维度层级
        </div>
      </div>
    </div>

    <div class="leftrig-box">
      <div class="leftrig-left-box" style="width: -webkit-calc(11% - 0px);">
        <Tree
          v-if="indexFilter === 1 && reFresh"
          :treeData="treeData"
          ref="tree"
          nodeKey="Id"
          :defaultProps="defaultProps"
          @nodeClick="nodeClick"
          lazy
          :load="loadNode"
        />
        <DimensionTree
          v-else-if="indexFilter === 2"
          :areaId="areaId"
          @changeNode="changeDimensionNode"
          ref="DTree"
        />
      </div>
      <div class="leftrig-rig-box" style="max-width: calc(88% - 1px);">
        <!--表格区开始-->
        <div class="margno-t">
          <div class="table-er-box">
            <el-button
              icon="el-icon-circle-plus-outline"
              v-if="this.indexFilter == 1"
              @click="addNewIndex"
              >添加新指标</el-button
            >
            <el-button
              icon="el-icon-refresh"
              v-if="this.indexFilter == 1"
              @click="importAreaIndex"
              >导入区域指标</el-button
            >
            <el-button
              icon="el-icon-refresh"
              v-if="this.indexFilter == 1"
              @click="addNewIndex(1)"
              >批量添加</el-button
            >
          </div>
          <el-table
            :data="tableData"
            border
            style="width: 100%;"
            tooltip-effect="dark"
          >
            <el-table-column
              fixed
              prop="pxh"
              label="排序"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              fixed
              prop="zbmc"
              label="指标名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="IsHierarchy"
              prop="bz"
              label="备注"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="IsHierarchy"
              label="维度"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ scope.row.sswdstr }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              prop="fjzbmc"
              label="父指标名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="df"
              label="指标积分"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="zblxStr"
              label="评价类型"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.zblx === 1">加分</span>
                <span v-else-if="scope.row.zblx === 2">减分</span>
                <span v-else-if="scope.row.zblx === 3">中评</span>
                <span v-else>无</span>
              </template>
            </el-table-column>
            <el-table-column label="评价方式" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.pjfs === 1">勾选打分</span>
                <span v-else-if="scope.row.pjfs === 2">自定义打分</span>
                <span v-else>其它来源</span>
              </template>
            </el-table-column>
            <el-table-column prop="tjlx" label="统计类型" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <span>{{ row.tjlx === 1 ? '累计得分' : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="IsHierarchy"
              prop="zbmrf"
              label="指标默认分"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="IsHierarchy"
              prop="zbmf"
              label="指标满分"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="IsHierarchy"
              fixed="right"
              label="操作"
              width="110"
            >
              <template slot-scope="scope">
                <el-button @click="editItem(scope.row)" type="text" size="small"
                  >编辑</el-button
                >
                <el-button
                  @click="delItem(scope.row)"
                  class="tab-wz-butta c-red"
                  type="text"
                  size="small"
                  >删除</el-button
                >
                <!-- 展示等级暂时注视掉 -->
                <!-- <el-button
                  class="tab-wz-butta c-red"
                  type="text"
                  size="small"
                  @click="showLevelDialog(scope.row)"
                  >展示等级</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
          <div class="prompt-title-box">
            温馨提示：1.建议评价类型和评价方式和指标积分只设置在末级指标上；2.建议只在一级指标上设置关联维度
          </div>
          <!--页码开始-->
          <div class="pagin-box">
            <div class="pagin-wz">
              <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
            </div>
          </div>
          <!--页码结束-->
        </div>
        <!--表格区结束-->
      </div>
    </div>
    <!-- 添加单个指标 -->
    <el-dialog
      class="index-dialog"
      title="添加指标"
      :visible.sync="dialogFormVisible"
      :before-close="cancelForm"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :ref="formName" :rules="rules">
        <el-form-item
          label="指标名称"
          :label-width="formLabelWidth"
          prop="zbmc"
        >
          <el-input v-model="form.zbmc" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          label="备注"
          v-if="form.pjfs !== 3"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.bz" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          label="指标积分"
          v-if="form.pjfs !== 3"
          :label-width="formLabelWidth"
          prop="df"
        >
          <el-input v-model="form.df" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          label="评价类型"
          :label-width="formLabelWidth"
          prop="zblx"
          v-if="form.pjfs !== 3"
        >
          <el-select v-model="form.zblx" placeholder="请选择评价类型">
            <el-option
              v-for="item in pjlx"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="评价方式"
          :label-width="formLabelWidth"
          prop="pjfs"
        >
          <el-select
            v-model="form.pjfs"
            placeholder="请选择评价方式"
            @change="getActivity"
          >
            <el-option
              v-for="item in pjfs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="末级维度" :label-width="formLabelWidth">
          <el-cascader
            :props="{ expandTrigger: 'hover' }"
            v-model="form.sswd"
            :options="sswd"
            :show-all-levels="false"
            placeholder="请选择末级维度"
            @change="handleWdSelected"
          ></el-cascader>
        </el-form-item>

        <el-form-item
          label="指标默认分"
          :label-width="formLabelWidth"
          prop="zbmrf"
        >
          <el-input v-model="form.zbmrf" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          label="指标满分"
          :label-width="formLabelWidth"
          prop="zbmf"
        >
          <el-input v-model="form.zbmf" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          key="sjly"
          v-if="form.pjfs === 3"
          label="数据来源"
          :label-width="formLabelWidth"
          prop="sjly"
        >
          <MyCascader
            :checkValue.sync="form.sjly"
            :options="sjlyOption"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item
          label="指标间隔分"
          :label-width="formLabelWidth"
          prop="zbjgf"
          v-if="form.pjfs !== 3"
        >
          <el-input v-model="form.zbjgf" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth" prop="pxh">
          <el-input-number
            v-model="form.pxh"
            autocomplete="on"
            :min="0"
            :step="1"
            :precision="0"
            :max="999"
          />
        </el-form-item>
        <el-form-item
          label="图标"
          v-if="form.pjfs !== 3"
          :label-width="formLabelWidth"
        >
          <!-- <el-input v-model="form.zbtp" autocomplete="on"></el-input> -->
          <Upload
            v-if="dialogFormVisible"
            :itemPic="form.zbtp ? form.zbtp : $store.state.defaultImage"
            :width="50"
            :height="50"
            @getResultUrl="getResultUrl"
            :circle="true"
            html
          />
        </el-form-item>
        <el-form-item label="注释" :label-width="formLabelWidth" prop="ZS">
          <el-input
            type="textarea"
            :maxlength="200"
            :rows="4"
            placeholder="请输入内容，最多200个字符"
            v-model="form.ZS"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="confirmForm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 批量添加输入框 -->
    <el-dialog
      title="批量添加"
      :visible.sync="batch.visible"
      width="30%"
      :before-close="
        () => {
          batch.visible = false
        }
      "
    >
      <el-input
        type="number"
        placeholder="请输入2-8的整数"
        v-model.number="batch.val"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="batch.visible = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="batch.val > 10 || batch.val < 2"
          @click="addBatch"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 批量添加指标-->
    <el-dialog
      title="批量添加"
      class="batch-form"
      :visible.sync="batch.formVisible"
      width="95%"
      :before-close="batchFormBeforeClose"
    >
      <el-form
        :class="`form${index}  batch-form-item`"
        v-for="(formItem, index) in batch.data"
        label-width="100px"
        :key="index"
        :model="formItem"
        :ref="`form${index}`"
        :rules="rules"
      >
        <el-form-item label="指标名称" prop="zbmc">
          <el-input v-model="formItem.zbmc" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item label="备注" v-if="formItem.pjfs !== 3">
          <el-input v-model="formItem.bz" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item label="指标积分" v-if="formItem.pjfs !== 3" prop="df">
          <el-input v-model="formItem.df" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item label="评价类型" v-if="formItem.pjfs !== 3" prop="zblx">
          <el-select v-model="formItem.zblx" placeholder="请选择评价类型">
            <el-option
              v-for="item in pjlx"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="评价方式" prop="pjfs">
          <el-select
            v-model="formItem.pjfs"
            placeholder="请选择评价方式"
            @change="getActivity"
          >
            <el-option
              v-for="item in pjfs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="末级维度">
          <el-cascader
            :props="{ expandTrigger: 'hover' }"
            v-model="formItem.sswd"
            :options="sswd"
            :show-all-levels="false"
            placeholder="请选择末级维度"
            @change="handleWdSelected"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="指标默认分" prop="zbmrf">
          <el-input v-model="formItem.zbmrf" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item label="指标满分" prop="zbmf">
          <el-input v-model="formItem.zbmf" autocomplete="on"></el-input>
        </el-form-item>

        <el-form-item
          label="数据来源"
          :key="`sjly${index}`"
          v-if="formItem.pjfs === 3"
          prop="sjly"
        >
          <MyCascader
            :checkValue.sync="formItem.sjly"
            :options="sjlyOption"
            placeholder="请选择"
          />
        </el-form-item>

        <el-form-item
          label="指标间隔分"
          v-if="formItem.pjfs !== 3"
          prop="zbjgf"
        >
          <el-input v-model="formItem.zbjgf" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth" prop="pxh">
          <el-input-number
            v-model="formItem.pxh"
            autocomplete="on"
            :min="0"
            :step="1"
            :precision="0"
            :max="999"
          />
        </el-form-item>
        <el-form-item label="图标" v-if="formItem.pjfs !== 3">
          <Upload
            :itemPic="formItem.zbtp ? formItem.zbtp : $store.state.defaultImage"
            :width="50"
            :height="50"
            @getResultUrl="getResultUrl($event, formItem)"
            :circle="true"
            html
          />
        </el-form-item>
        <el-form-item label="注释" :label-width="formLabelWidth" prop="ZS">
          <el-input
            type="textarea"
            :maxlength="200"
            :rows="4"
            placeholder="请输入内容，最多200个字符"
            v-model="formItem.ZS"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="backWards">向后同步</el-button>
        <el-button @click="batch.formVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 等级展示框 -->
    <LevelDialog
      :index="levelDialog.index"
      :visible.sync="levelDialog.visible"
      :option="levelDialog.option"
      @refresh="
        getData()
        levelDialog.visible = false
      "
    />
  </div>
</template>

<script>
import Pager from '@/components/Pager.vue'
// import Tree from "@/views/manager/schoolindex/Tree.vue";
import Tree from '@/components/Tree.vue'
import DimensionTree from '@/views/manager/schoolindex/DimensionTree.vue'
import Upload from '@/components/Upload.vue'
import { isFloat, isUintNumber, max1000 } from '@/style/js/Validator.js'
import { Compare } from '@/style/js/Common.js'
import MyCascader from '@/views/manager/schoolindex/mycaster.vue'
import LevelDialog from '@/views/manager/schoolindex/levelDialog.vue'
export default {
  components: {
    Pager,
    Tree,
    DimensionTree,
    Upload,
    MyCascader,
    LevelDialog
  },
  data() {
    return {
      treeData: [],
      nodeType: 0,
      reFresh: true,
      visible: true,
      pjlx: [
        {
          value: 1,
          label: '加分'
        },
        {
          value: 2,
          label: '减分'
        },
        {
          value: 3,
          label: '中评'
        }
      ],
      pjfs: [
        {
          value: 1,
          label: '勾选打分'
        },
        {
          value: 2,
          label: '自定义打分'
        },
        {
          value: 3,
          label: '其它来源'
        }
      ],
      sswd: [
        {
          value: 'zhinan',
          label: '指南',
          children: []
        }
      ],
      tableData: [],
      areaId: '',
      parentId: '',
      indexFilter: 1,
      dimensionId: '',
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0
      },
      dialogFormVisible: false,
      formLabelWidth: '120px',
      level: 1,
      newNode: [],
      node_id: '',
      form: {},
      currentNode: {},
      schoolId: '',
      cjr: '',
      defaultProps: {
        children: 'children',
        label: 'Name',
        isLeaf: 'noChilden',
        id: 'Id'
      },
      rules: {
        zbmc: [
          {
            required: true,
            message: '请输入指标名称',
            trigger: 'change'
          }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        df: [
          {
            required: true,
            message: '请输入指标积分',
            trigger: 'change'
          },
          {
            validator: isUintNumber,
            message: '分数必须为自然数',
            trigger: 'change'
          },
          {
            validator: isFloat,
            message: '分数最高精确到2位小数',
            trigger: 'change'
          }
        ],
        zblx: [
          {
            required: true,
            message: '请选择评价类型',
            trigger: 'change'
          }
        ],
        pjfs: [
          {
            required: true,
            message: '请选择评价方式',
            trigger: 'change'
          }
        ],
        zbmrf: [
          {
            validator: isUintNumber,
            message: '分数必须为自然数',
            trigger: 'change'
          },
          {
            validator: isFloat,
            message: '分数最高精确到2位小数',
            trigger: 'change'
          }
        ],
        zbmf: [
          {
            validator: isUintNumber,
            message: '分数必须为自然数',
            trigger: 'change'
          },
          {
            validator: isFloat,
            message: '分数最高精确到2位小数',
            trigger: 'change'
          },
          {
            validator: max1000,
            message: '最大1000',
            trigger: 'change'
          }
        ],
        zbjgf: [
          {
            validator: isUintNumber,
            message: '分数必须为自然数',
            trigger: 'change'
          },
          {
            validator: isFloat,
            message: '分数最高精确到2位小数',
            trigger: 'change'
          }
        ],
        sjly: [
          {
            required: true,
            message: '数据来源不能为空',
            trigger: 'change'
          }
        ]
      },
      formName: 'form',
      batch: {
        visible: false,
        val: 2,
        data: [],
        formVisible: false
      },
      // 数据来源下拉
      sjlyOption: [],
      //等级展示dialog相关
      levelDialog: {
        visible: false,
        option: [],
        index: {}
      },
      LX: 0
    }
  },

  methods: {
    resetData() {
      // 根据不同路由地址，返回不同数据
      if (this.$route.fullPath === '/schoolindex1') {
        this.LX = 1
      } else if (this.$route.fullPath === '/schoolindex2') {
        this.LX = 2
      }
    },
    importAreaIndex() {
      this.$confirm(
        '导入后所有和区域指标关联的数据将被区域指标的数据更新！是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$api.post(
            '/v1/schoolindex/ImportAreaIndex',
            {
              token: this.$store.state.token,
              AreaId: this.areaId,
              SchoolId: this.schoolId
            },
            r => {
              const resp = r.Target
              let msgType = 'error'
              if (resp.Code === 0) {
                msgType = 'success'
                this.reFresh = false
                this.getAreaId()
                this.getData(true)
              }
              this.$message({
                type: msgType,
                message: resp.Message
              })
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消导入区域指标'
          })
        })
    },
    changeDimensionNode(value) {
      this.dimensionId = ''
      if (this.$refs.DTree.$refs.tree.getCurrentKey()) {
        this.dimensionId = value
      }
      this.getData()
    },
    getAreaId() {
      this.$api.post(
        '/v1/areaschool/GetAreaSch',
        {
          token: this.$store.state.token,
          Id: this.schoolId
        },
        r => {
          var resp = r.Target
          var data = resp.Target
          if (
            data !== undefined &&
            data !== null &&
            data.QYId !== undefined &&
            data.QYId !== null
          ) {
            this.areaId = data.QYId
            this.getDimensions()
          }

          // for(let i=0;i<data.length;i++){
          //   let childrenList=data[i].ChildrenList
          //   this.sswd.push({value:data[i].Id,label:data[i].WDMC,children:childrenList})
          // }
          this.reFresh = true
        }
      )
    },
    handleWdSelected(value) {
      console.log(value, 'value')
      if (value) {
        if (value.length > 1) {
          this.form.sswd = value[1]
        } else {
          this.form.sswd = value[0]
        }
      }
    },
    getDimensions() {
      const pageinfo = {
        PageIndex: 0,
        PageSize: parseInt(Number.MAX_SAFE_INTEGER / 10000000)
      }
      this.sswd = []
      this.$api.post(
        '/v1/dimension/GetDimensionTree',
        {
          LX: this.LX,
          token: this.$store.state.token,
          AreaId: this.areaId,
          PagerInfo: pageinfo
        },
        r => {
          this.sswd = JSON.parse(JSON.stringify(r.Target))
          this.handle_Sswd()
        }
      )
    },
    getData() {
      // const that = this;
      this.$api.post(
        '/v1/schoolindex/GetIndex',
        {
          token: this.$store.state.token,
          ParentId: this.parentId,
          SchoolId: this.schoolId,
          IndexFilter: this.indexFilter,
          DimensionId: this.dimensionId,
          pagerInfo: this.pagerInfo,
          LX: this.LX
        },
        r => {
          var { PagerInfo, Target } = r.Target
          this.pagerInfo.totalCount = PagerInfo.RowCount
          if (Target && Target.length > 0) {
            this.tableData = Target.map(item => {
              let obj = {}
              Object.keys(item).forEach(key => {
                obj[key.toLowerCase()] = item[key]
              })
              obj.ZS = item.ZS
              obj.tjlx = 1
              obj.sswdstr = ''
              return obj
            })
          } else this.tableData = []
          this.getDimensions()
          this.tableData.sort(Compare('pxh'))
          this.$nextTick(() => {
            let key = this.indexFilter === 1 ? 'tree' : 'DTree'
            let node =
              Object.keys(this.currentNode).length > 0
                ? this.currentNode
                : this.$refs[key].$refs.tree.getNode('0')
            if (node && node.data) {
              //主动调用展开节点方法 重新查询该节点下的所有子节点
              node.loaded = false
              node.expand()
            }
          })
        }
      )
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val
      this.getData()
    },
    // eslint-disable-next-line no-unused-vars
    changeNode(obj, node) {
      this.parentId = obj.Id
      // this.areaId = obj.ParentId;
      this.level = 1
      this.nodeType = obj.Nodetype
      if (obj.Nodetype === 1) {
        this.areaId = obj.Id
      } else if (obj.Nodetype === 2) {
        this.dimensionId = obj.Id
      } else if (obj.Nodetype === 3) {
        this.level = ++obj.level
      }
      // this.node_id = obj.nodeId;
      // this.currentNode = node;
      this.getData()
    },
    confirmForm() {
      this.$refs[this.formName].validate(valid => {
        if (valid) {
          this.$confirm('确认提交？')
            // eslint-disable-next-line no-unused-vars
            .then(r => {
              this.submitForm()
            })
            // eslint-disable-next-line no-unused-vars
            .catch(r => {})
        } else {
          return false
        }
      })
    },
    cancelForm() {
      this.$confirm('确认关闭？').then(() => {
        this.dialogFormVisible = false
        this.form = {}
        this.$refs[this.formName].resetFields()
      })
      // this.$confirm("检测到未保存的内容，是否在离开页面前保存修改？", "", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "保存",
      //   cancelButtonText: "放弃修改"
      // })
      //   .then(() => {
      //     //执行提交方法,执行成功后回调函数执行以下代码
      //     this.$refs[this.formName].validate(valid => {
      //       if (valid) {
      //         this.submitForm();
      //       } else {
      //         return false;
      //       }
      //     });
      //   })
      //   .catch(action => {
      //     if (action === "cancel") {
      //       this.dialogFormVisible = false;
      //       this.form = {};
      //       this.$refs[this.formName].resetFields();
      //     }
      //   });
    },
    submitForm() {
      if (
        this.parentId === undefined ||
        this.parentId === null ||
        this.parentId === ''
      ) {
        this.parentId = this.schoolId
      }
      this.form.fjid = this.parentId
      this.form.ssxx = this.schoolId
      this.form.cj = this.level
      this.form.NodeType = this.nodeType
      this.form.LX = this.LX

      if (this.form.pjfs === 3) {
        this.form.EvaId = this.form.sjly[0] && this.form.sjly[0].activityId
        this.form.IndexIds =
          this.form.sjly && this.form.sjly.map(item => item.IndexId)
      } else {
        this.form.EvaId = ''
        this.form.IndexIds = []
      }
      if (
        this.form.zbmrf !== 0 &&
        this.form.zbmrf !== '0' &&
        !this.form.zbmrf
      ) {
        this.form.zbmrf = 0
      }

      this.$api.post(
        '/v1/schoolindex/EditIndex',
        {
          IndexList: [this.form],
          SchoolId: this.schoolId,
          token: this.$store.state.token
        },
        r => {
          if (r.Code === 0) this.getData()
          this.dialogFormVisible = false
          this.form = {}
          // this.$refs[this.formName].resetFields();
          this.tableData.sort(Compare('pxh'))
        }
      )
    },
    editItem(data) {
      console.log(data, 'data')
      this.dialogFormVisible = true
      this.form = Object.assign({}, data)
    },
    delItem(data) {
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = []
          ids.push(data.id)
          this.$api.post(
            '/v1/schoolindex/DelIndex',
            {
              Ids: ids,
              SchoolId: this.schoolId,
              token: this.$store.state.token
            },
            r => {
              if (r.Code === 0) this.getData()
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleChangeTabs(indexfilter) {
      const elements = document.getElementsByClassName('cent-butt')
      if (this.indexFilter !== indexfilter) {
        if (this.indexFilter === 1) {
          this.dimensionId = ''
          this.indexFilter = 2
          elements[1].className =
            'cent-butt cent-butt-sty whole-col whole-col-hov'
          elements[0].className = 'cent-butt whole-col-hov'
        } else {
          this.indexFilter = 1
          elements[0].className =
            'cent-butt cent-butt-sty whole-col whole-col-hov'
          elements[1].className = 'cent-butt whole-col-hov'
        }
        this.tableData = []
        this.pagerInfo.totalCount = 0
        if (this.indexFilter === 1) {
          console.log(12, '12')
          this.getData()
        }
        this.reFresh = false
        this.$nextTick(() => {
          this.reFresh = true
          this.tableData = []
          this.pagerInfo.totalCount = 0
        })
      }
    },
    getResultUrl(filePath, formItem) {
      if (formItem) {
        formItem.ZBTP = filePath
      } else {
        this.form.ZBTP = filePath
      }
      console.log(filePath, formItem, 'eeee')
    },
    updateNode(newNode, isUpdate) {
      let key = this.$refs.Tree.$refs.tree.getCurrentKey()
      const nodes = []
      if (!key) {
        key = this.schoolId
        var newdata = this.$refs.Tree.$refs.tree.getNode(key)
        nodes.push(newdata)
        newdata.Key = newNode.data_id
        newdata.data = newNode
      } else {
        const list = this.$refs.Tree.$refs.tree.getNode(key).childNodes
        for (let i = 0; i < list.length; i++) {
          nodes.push(list[i].data)
        }
        if (isUpdate && isUpdate === 1) {
          nodes.splice(
            nodes.findIndex(c => c.data_id === newNode.data_id),
            1,
            newNode
          )
        } else {
          nodes.unshift(newNode)
        }
      }
      this.$refs.Tree.$refs.tree.updateKeyChildren(key, nodes)
    },
    deleteNode(nodeId) {
      if (!this.parentId) {
        this.$refs.Tree.$refs.tree.setCurrentKey(this.schoolId)
      }
      const key = this.$refs.Tree.$refs.tree.getCurrentKey()
      const list = this.$refs.Tree.$refs.tree.getNode(key).childNodes
      if (list && list.length > 0) {
        const nodes = []
        for (let i = 0; i < list.length; i++) {
          nodes.push(list[i].data)
        }
        nodes.splice(
          nodes.findIndex(c => c.data_id === nodeId),
          1
        )
        this.$refs.Tree.$refs.tree.updateKeyChildren(key, nodes)
      }
    },
    getTreeData(parentId, resolve) {
      this.$api.post(
        '/v1/schoolindex/GetTree',
        {
          ParentId: parentId,
          token: this.$store.state.token,
          IndexFilter: this.indexFilter,
          SchoolId: this.schoolId,
          LX: this.LX
        },
        r => {
          let data = r.Target.Target
          data.map(c => (c.noChilden = c.HasChilden === 1 ? false : true))
          resolve(data.sort(Compare('OrderNum')))
        }
      )
    },
    nodeClick(data, node) {
      this.currentNode = node
      this.parentId = data.Id === '0' ? '' : data.Id
      this.level = 1
      this.nodeType = data.NodeType
      if (data.NodeType === 1) {
        this.areaId = data.Id
      } else if (data.NodeType === 2) {
        this.dimensionId = data.Id
      } else if (data.NodeType === 3) {
        this.level = node.level
      }
      // this.node_id = obj.nodeId;
      // this.currentNode = node;
      if (data.id === '0') this.getData(true)
      else this.getData()
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([
          {
            Name: '全部',
            Id: '0',
            noChilden: false
          }
        ])
      }
      this.getTreeData(node.data.Id, resolve)
    },
    // 批量添加
    addBatch() {
      let arr = []
      for (let i = 1; i <= this.batch.val; i++) {
        arr.push({})
      }
      this.batch.data = arr
      this.batch.visible = false
      this.batch.formVisible = true
    },
    // 向后同步
    backWards() {
      this.batch.data.forEach((form, idx) => {
        if (idx !== 0) {
          let obj = JSON.parse(JSON.stringify(this.batch.data[0]))
          Object.keys(obj).forEach(key => this.$set(form, key, obj[key]))
        }
      })
    },
    // 批量新增
    batchSubmit() {
      try {
        this.batch.data.forEach((form, idx) => {
          let str = 'form' + idx
          this.$refs[str][0].validate(valid => {
            if (!valid) throw new Error(`${str}未填`)
          })

          if (form.pjfs === 3) {
            form.EvaId = form.sjly[0].activityId
            form.IndexIds = form.sjly.map(it => it.IndexId)
          } else {
            form.EvaId = ''
            form.IndexIds = []
          }
          form.fjid =
            this.currentNode.data.Id === '0'
              ? this.schoolId
              : this.currentNode.data.Id //指标父id
          form.ssxx = this.schoolId
          form.cj = this.level //node在tree中的层级 可能不需要
          form.NodeType = this.nodeType //指标树分类：维度还是层级
          form.cjr = this.cjr //参加人
        })
        this.batch.data.forEach(item => {
          item.LX = this.LX
          if (item.sswd) item.sswd = item.sswd[1]
          if (item.zbmrf !== 0 && item.zbmrf !== '0' && !item.zbmrf)
            item.zbmrf = 0
        })
        this.$api.post(
          '/v1/schoolindex/EditIndex',
          {
            IndexList: this.batch.data,
            SchoolId: this.schoolId,
            token: this.$store.state.token
          },
          res => {
            if (res.Code === 0) {
              this.batch.formVisible = false
              this.batch.data = []
              this.getData()
            }
          }
        )
      } catch (err) {
        console.log(err)
      }
    },
    // 添加新指标
    addNewIndex(type) {
      if (Object.keys(this.currentNode).length === 0)
        return this.$message('请选择父级指标再新增')

      if (type === 1) this.batch.visible = true
      else this.dialogFormVisible = true
    },
    // 获取活动
    getActivity(val) {
      if (val === 3) {
        this.$api.post(
          '/v1/evaluationactivity/GetEvaluationActivityList',
          {
            token: this.$store.state.token,
            SchoolId: this.schoolId
          },
          res => {
            if (res.Code == 0) {
              res.Target = res.Target.filter(item => item.PJFS === 1)
              res.Target.forEach(item => {
                item.label = item.PJHDMC
                item.value = item.Id
                item.leaf = false
                item.children = []
                item.disabled = false
              })
              this.sjlyOption = res.Target
            }
          }
        )
      }
    },
    showLevelDialog(row) {
      this.getLevel()
      this.levelDialog.index = row
      this.levelDialog.visible = true
    },
    // 获取等级
    getLevel() {
      this.$api.post(
        '/v1/levelmanager/GetLeaveSet',
        {
          token: this.$store.state.token,
          SchoolId: this.schoolId
        },
        r => {
          if (r.Code === 0)
            this.levelDialog.option = r.Target.filter(item => item.DJLX === 2)
        }
      )
    },
    // 处理维度label
    handle_Sswd() {
      this.tableData.forEach(row => {
        try {
          this.sswd.forEach(item => {
            if (item.value === row.sswd) {
              row.sswdstr = item.label
              throw new Error('中断循环')
            } else {
              if (item.children) {
                item.children.forEach(child => {
                  if (child.value === row.sswd) {
                    row.sswdstr = child.label
                    throw new Error('中断循环')
                  }
                })
              }
            }
          })
        } catch (err) {
          //
        }
      })
    },
    // 批量添加表单关闭前提示
    batchFormBeforeClose() {
      this.$confirm('关闭后数据将重置，确认关闭？')
        .then(() => {
          this.batch.formVisible = false
        })
        .catch(() => {})
    }
  },
  created: function() {
    this.resetData()
    const user = JSON.parse(localStorage.getItem('userinfo'))
    if (user !== undefined && user !== '' && user !== null) {
      if (user.SSZZId !== undefined && user.SSZZId !== null) {
        this.schoolId = user.SSZZId
        this.cjr = user.XSM
      }
    }
    this.getAreaId()
    this.getData()
  },
  computed: {
    IsHierarchy: function() {
      return this.indexFilter === 1
    }
  },
  watch: {
    $route: {
      handler: 'resetData'
    }
  }
}
</script>

<style lang="scss">
.cont-whole-box {
  .el-dialog__wrapper.batch-form {
    .el-dialog__body {
      display: flex;
      flex-wrap: wrap;

      .el-form.batch-form-item {
        width: 317px;
        padding-right: 15px;
        margin-right: 10px;
        margin-bottom: 20px;
        border-right: 1px solid #ede6e6;
        border-bottom: 1px solid #ede6e6;
        box-shadow: 1px 1px 4px #ede6e6;
      }
    }
  }
}
</style>
